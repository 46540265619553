import * as Constants from "../../core/Constants";
import { CoreInterfaces } from "../../core/Models";
import { useContext, useState } from "react";
import { DataGrid, GridPaginationModel } from "@mui/x-data-grid";
import { GridColDef } from "@mui/x-data-grid";
import { t } from "i18next";
import {
  fetchData,
  fetchEngagementLettersSummaryView,
  isAdministrator,
  isOfficeManager,
} from "src/utils";
import GtEngagementLetterMenu from "../Common/GtEngagementLetterMenu";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import EngagementLettersFilterPanel from "./EngagementLettersFilterPanel";
import { NavLink } from "react-router-dom";
import { LoadingContext } from "src/contexts/LoadingContext";
import { useSnackBar } from "src/contexts/SnackbarContext";
import GtChangeOwnerDialog from "../Common/GtChangeOwnerDialog";
import GtChangeTeamDialog from "../Common/GtChangeTeamDialog";
import { AppContext } from "src/contexts/AppContext";
import InterpreterModeOutlinedIcon from "@mui/icons-material/InterpreterModeOutlined";
import { Tooltip } from "@mui/material";

const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

function EngagementLettersSummaryViewPanel(): JSX.Element {
  const { globalState } = useContext(AppContext);
  const { showSnackBar } = useSnackBar();
  const [rows, setRows] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { updateApplicationLoadingState } = useContext(LoadingContext);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });
  const [
    selectedEngagementLettersSummaryItem,
    setSelectedEngagementLettersSummaryItem,
  ] = useState(null);
  const [isGtChangeOwnerDialogVisible, setIsGtChangeOwnerDialogVisible] =
    useState(false);
  const [isGtChangeTeamDialogVisible, setIsGtChangeTeamDialogVisible] =
    useState(false);
  const [filter, setFilter] = useState(null);

  function loadData(
    filterData: any,
    paginationModel: GridPaginationModel
  ): void {
    updateApplicationLoadingState(true);
    fetchEngagementLettersSummaryView({
      Skip: paginationModel.page * paginationModel.pageSize,
      Take: paginationModel.pageSize,
      IsActive: true,
      ...filterData,
    })
      .then(
        (engagementLettersSummary: CoreInterfaces.EngagementLettersSummary) => {
          setRows(engagementLettersSummary.Items);
          setTotalCount(engagementLettersSummary.TotalCount);
          updateApplicationLoadingState(false);
        }
      )
      .catch(() => {
        updateApplicationLoadingState(false);
        showSnackBar(
          t("General.EngagementLettersSummaryViewLoadFail"),
          "error"
        );
      });
  }

  function setEngagementVersionActivationState(
    engagementLettersSummaryItem: CoreInterfaces.EngagementLettersSummaryItem,
    isActive: boolean
  ): void {
    updateApplicationLoadingState(true);
    const payload: CoreInterfaces.EngagementConfigurationActivationUpdate = {
      EngagementConfigurationId: engagementLettersSummaryItem.Id,
      BusinessOpportunityId: engagementLettersSummaryItem.BusinessOpportunityId,
      IsActive: isActive,
    };
    fetchData(
      Constants.APIPath.SetEngagementVersionActivationState,
      Constants.APIMethod.PUT,
      payload
    )
      .then(() => {
        loadData(filter, paginationModel);
      })
      .catch(() => {
        updateApplicationLoadingState(false);
        showSnackBar(t("General.AnErrorHasOccured"), "error");
      });
  }

  function changeOwnerDialogOpen(
    engagementLettersSummaryItem: CoreInterfaces.EngagementLettersSummary
  ): void {
    setSelectedEngagementLettersSummaryItem(engagementLettersSummaryItem);
    setIsGtChangeOwnerDialogVisible(true);
  }

  function changeTeamDialogOpen(
    engagementLettersSummaryItem: CoreInterfaces.EngagementLettersSummary
  ): void {
    setSelectedEngagementLettersSummaryItem(engagementLettersSummaryItem);
    setIsGtChangeTeamDialogVisible(true);
  }

  const columns = getColumnsDefinition(
    globalState,
    setEngagementVersionActivationState,
    changeOwnerDialogOpen,
    changeTeamDialogOpen
  );

  return (
    <article>
      <EngagementLettersFilterPanel
        onFilter={(filterData) => {
          loadData(filterData, paginationModel);
          setFilter(filterData);
        }}
      ></EngagementLettersFilterPanel>
      <section className="gt-engagementLettersSummaryViewPanel">
        <DataGrid
          autoHeight
          getRowId={(row) => row.Id}
          rows={rows}
          columns={columns}
          rowHeight={44}
          columnHeaderHeight={32}
          hideFooterPagination={false}
          hideFooterSelectedRowCount={true}
          pagination
          filterMode="server"
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={(nextPaginationModel) => {
            setPaginationModel(nextPaginationModel);
            loadData(filter, nextPaginationModel);
          }}
          pageSizeOptions={[10, 25, 50]}
          rowCount={totalCount}
          initialState={{
            sorting: {
              sortModel: [{ field: "SigningDate", sort: "asc" }],
            },
          }}
        />
      </section>
      {isGtChangeOwnerDialogVisible && (
        <GtChangeOwnerDialog
          engagementLettersSummaryItem={selectedEngagementLettersSummaryItem}
          onOwnerChanged={function (): void {
            loadData(filter, paginationModel);
            setIsGtChangeOwnerDialogVisible(false);
          }}
          onChangeOwnerDialogClose={function (): void {
            setIsGtChangeOwnerDialogVisible(false);
          }}
        />
      )}
      {isGtChangeTeamDialogVisible && (
        <GtChangeTeamDialog
          engagementLettersSummaryItem={selectedEngagementLettersSummaryItem}
          onTeamChanged={function (): void {
            loadData(filter, paginationModel);
            setIsGtChangeTeamDialogVisible(false);
          }}
          onChangeTeamDialogClose={() => {
            setIsGtChangeTeamDialogVisible(false);
          }}
        />
      )}
    </article>
  );
}

function getColumnsDefinition(
  appState: CoreInterfaces.AppState,
  onActivationChangeFn: Function,
  onChangeOwnerDialogOpenFn: Function,
  onChangeTeamDialogOpenFn: Function
): GridColDef[] {
  return [
    {
      field: "CompanyName",
      headerName: t("General.ClientName"),
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: (params: {
        row: CoreInterfaces.EngagementLettersSummaryItem;
      }) => {
        return `gt-lettersSummaryCell ${
          !params.row.IsActive ? "gt-lettersSummaryCell--deactivated" : ""
        }`;
      },
      renderCell(params: { row: CoreInterfaces.EngagementLettersSummaryItem }) {
        return (
          <article>
            <Tooltip title={params.row.CompanyName}>
              <span>{params.row.CompanyName}</span>
            </Tooltip>
          </article>
        );
      },
    },
    {
      field: "SigningDate",
      headerName: t("General.SigningDate"),
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: (params: {
        row: CoreInterfaces.EngagementLettersSummaryItem;
      }) => {
        let cssClassNames = "gt-lettersSummaryCell";
        if (!params.row.IsActive) {
          cssClassNames = `${cssClassNames} gt-lettersSummaryCell--deactivated`;
        }
        switch (params.row.ValidityStatus) {
          case Constants.ValidityStatus.Valid: {
            cssClassNames = `${cssClassNames} gt-lettersSummaryCell--valid`;
            break;
          }
          case Constants.ValidityStatus.ToExpire: {
            cssClassNames = `${cssClassNames} gt-lettersSummaryCell--toExpire`;
            break;
          }
          case Constants.ValidityStatus.Expired: {
            cssClassNames = `${cssClassNames} gt-lettersSummaryCell--expired`;
            break;
          }
        }

        return cssClassNames;
      },
      renderCell(params: { row: CoreInterfaces.EngagementLettersSummaryItem }) {
        return (
          <article>
            {params.row.SigningDate &&
              new Date(params.row.SigningDate).toLocaleDateString([], {
                ...options,
                timeZone: "UTC",
              })}
          </article>
        );
      },
    },
    {
      field: "OrganisationNumber",
      headerName: t("General.ClientOrgNumber"),
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: (params: {
        row: CoreInterfaces.EngagementLettersSummaryItem;
      }) => {
        return `gt-lettersSummaryCell ${
          !params.row.IsActive ? "gt-lettersSummaryCell--deactivated" : ""
        }`;
      },
      renderCell(params: { row: CoreInterfaces.EngagementLettersSummaryItem }) {
        return <article>{params.row.OrganisationNumber}</article>;
      },
    },
    {
      field: "Office",
      headerName: t("General.Office"),
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: (params: {
        row: CoreInterfaces.EngagementLettersSummaryItem;
      }) => {
        return `gt-lettersSummaryCell ${
          !params.row.IsActive ? "gt-lettersSummaryCell--deactivated" : ""
        }`;
      },
      renderCell(params: { row: CoreInterfaces.EngagementLettersSummaryItem }) {
        return <article>{params.row.Office}</article>;
      },
    },
    {
      field: "BusinessUnit",
      headerName: t("General.BusinessArea"),
      flex: 2,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: (params: {
        row: CoreInterfaces.EngagementLettersSummaryItem;
      }) => {
        return `gt-lettersSummaryCell ${
          !params.row.IsActive ? "gt-lettersSummaryCell--deactivated" : ""
        }`;
      },
      renderCell(params: { row: CoreInterfaces.EngagementLettersSummaryItem }) {
        return (
          <article>
            {t(`Options.BusinessArea.${params.row.BusinessUnit}`)}
          </article>
        );
      },
    },
    {
      field: "gtOwner",
      headerName: t("General.GtOwner"),
      flex: 2,
      sortable: true,
      disableColumnMenu: true,
      cellClassName: (params: {
        row: CoreInterfaces.EngagementLettersSummaryItem;
      }) => {
        return `gt-lettersSummaryCell ${
          !params.row.IsActive ? "gt-lettersSummaryCell--deactivated" : ""
        }`;
      },
      valueGetter: (params: {
        row: CoreInterfaces.EngagementLettersSummaryItem;
      }) => {
        return `${params.row.OwnerFirstName || ""} ${
          params.row.OwnerLastName || ""
        }`;
      },
      renderCell(params: { row: CoreInterfaces.EngagementLettersSummaryItem }) {
        return (
          <article>
            <Tooltip
              title={`${params.row.OwnerFirstName} ${params.row.OwnerLastName}`}
            >
              <span>
                {params.row.OwnerFirstName} {params.row.OwnerLastName}
              </span>
            </Tooltip>
          </article>
        );
      },
    },
    {
      field: "Version",
      headerName: t("General.Version"),
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
      renderCell(params: { row: CoreInterfaces.EngagementLettersSummaryItem }) {
        return <article>{params.row.Version}</article>;
      },
    },
    {
      field: "EngagementStatus",
      headerName: t("General.Status"),
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (params: {
        row: CoreInterfaces.EngagementLettersSummaryItem;
      }) =>
        t(
          `Options.EngagementLetterSummaryStatus.${params.row.EngagementStatus}`
        ),
      renderCell(params: { row: CoreInterfaces.EngagementLettersSummaryItem }) {
        return (
          <article>
            <Tooltip
              title={t(
                `Options.EngagementLetterSummaryStatus.${params.row.EngagementStatus}`
              )}
            >
              <span>
                {t(
                  `Options.EngagementLetterSummaryStatus.${params.row.EngagementStatus}`
                )}
              </span>
            </Tooltip>
          </article>
        );
      },
    },
    {
      field: "Team",
      headerName: t("General.Team"),
      flex: 2,
      sortable: true,
      valueGetter: (params: {
        row: CoreInterfaces.EngagementLettersSummaryItem;
      }) => params.row.Team?.DisplayName,
      disableColumnMenu: true,
      renderCell(params: { row: CoreInterfaces.EngagementLettersSummaryItem }) {
        return (
          <article>
            <Tooltip title={params.row.Team?.DisplayName}>
              <span>{params.row.Team?.DisplayName}</span>
            </Tooltip>
          </article>
        );
      },
    },
    {
      field: "LastModifiedDate",
      headerName: t("General.ModifiedDate"),
      flex: 1,
      sortable: true,
      disableColumnMenu: true,
      renderCell(params: { row: CoreInterfaces.EngagementLettersSummaryItem }) {
        return (
          <article>
            {new Date(params.row.LastModifiedDate).toLocaleDateString([], {
              ...options,
              timeZone: "UTC",
            })}
          </article>
        );
      },
    },
    {
      field: "actions",
      flex: 1,
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell(params: { row: CoreInterfaces.EngagementLettersSummaryItem }) {
        return (
          <article className="gt-lettersSummaryActionCell">
            <NavLink
              target="_blank"
              to={`/?${Constants.QueryParams.businessOpportunityId}=${params.row.BusinessOpportunityId}&${Constants.QueryParams.clientId}=${params.row.ClientId}&${Constants.QueryParams.unit}=${params.row.BusinessUnit}&${Constants.QueryParams.engagementConfigurationId}=${params.row.Id}`}
            >
              <OpenInNewOutlinedIcon
                className="gt-lettersSummaryActionCell__linkIcon"
                fontSize="small"
              />
            </NavLink>
            {(isAdministrator(appState) || isOfficeManager(appState)) && (
              <PersonOutlineOutlinedIcon
                className="gt-lettersSummaryActionCell__userIcon"
                onClick={() => {
                  onChangeOwnerDialogOpenFn(params.row);
                }}
              />
            )}
            <InterpreterModeOutlinedIcon
              className="gt-lettersSummaryActionCell__userIcon"
              onClick={() => {
                onChangeTeamDialogOpenFn(params.row);
              }}
            />
            <GtEngagementLetterMenu
              engagementLetter={params.row}
              onToggleActiveStatus={(value: boolean) => {
                onActivationChangeFn(params.row, value);
              }}
            />
          </article>
        );
      },
    },
  ];
}

export default EngagementLettersSummaryViewPanel;
